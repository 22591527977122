/**
 * @generated SignedSource<<de57b252ca9d5010f34cf4c9f7fcc9bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ImageContentType = "GIF" | "JPEG" | "PNG" | "WEBP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GuildPrimaryPhotoNew_guild$data = {
  readonly name: string;
  readonly primaryPhoto: {
    readonly contentType: ImageContentType;
    readonly rowId: String;
  } | null | undefined;
  readonly rowId: String;
  readonly " $fragmentType": "GuildPrimaryPhotoNew_guild";
};
export type GuildPrimaryPhotoNew_guild$key = {
  readonly " $data"?: GuildPrimaryPhotoNew_guild$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuildPrimaryPhotoNew_guild">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuildPrimaryPhotoNew_guild",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "primaryPhoto",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Guild",
  "abstractKey": null
};
})();

(node as any).hash = "f7822f4dd36dc8dd0b873d53813737c4";

export default node;
