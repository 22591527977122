import React, { useMemo } from 'react'
import { useFragment, graphql } from 'react-relay'

import { ViewProps, getTokenValue, Token, styled, View } from 'tamagui'

import { Image } from '../Image'
import { Icon } from '../Icon'

import { IMAGEKIT_URL } from '../../data/config/url'

import { GuildPrimaryPhotoNew_guild$key } from './__generated__/GuildPrimaryPhotoNew_guild.graphql'

const GuildDefaultFrame = styled(View, {
  name: 'GuildDefaultPhoto',
  backgroundColor: '$grey0',

  borderWidth: 1,
  borderColor: 'rgba(19, 0, 102, 0.16)',

  variants: {
    size: {
      '...size': (size, { tokens }) => {
        const sizeToken = tokens.size[size]

        if (
          typeof sizeToken === 'object' &&
          'val' in sizeToken &&
          sizeToken.val > 40
        ) {
          return {
            boxShadow: '0 0 0 3px #FFFFFF',
            borderRadius: '$4',
          }
        }

        return {
          boxShadow: '0 0 0 1px #FFFFFF',
          borderRadius: '$2',
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$5',
  },
} as const)

const GuildPrimaryPhotoFrame = styled(Image, {
  name: 'GuildPrimaryPhoto',
  backgroundColor: '$grey0',

  borderWidth: 1,
  borderColor: 'rgba(19, 0, 102, 0.16)',

  variants: {
    size: {
      '...size': (size, { tokens }) => {
        const sizeToken = tokens.size[size]

        if (
          typeof sizeToken === 'object' &&
          'val' in sizeToken &&
          sizeToken.val > 40
        ) {
          return {
            boxShadow: '0 0 0 3px #FFFFFF',
            borderRadius: '$4',
          }
        }

        return {
          boxShadow: '0 0 0 1px #FFFFFF',
          borderRadius: '$2',
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$5',
  },
} as const)

export const GuildPrimaryPhoto = GuildPrimaryPhotoFrame.styleable<
  HTMLImageElement,
  {
    guild: GuildPrimaryPhotoNew_guild$key
    size: Token
  } & ViewProps
>(({ guild, size = '$5', ...props }, ref) => {
  const data = useFragment(
    graphql`
      fragment GuildPrimaryPhotoNew_guild on Guild {
        name
        rowId

        primaryPhoto {
          rowId
          contentType
        }
      }
    `,
    guild
  )

  const parsedSize = useMemo(() => {
    return getTokenValue(size, 'size')
  }, [size])

  if (
    !data.primaryPhoto ||
    !data.primaryPhoto.rowId ||
    !data.primaryPhoto.contentType
  ) {
    return (
      <GuildDefaultFrame
        {...props}
        ref={ref}
        size={size}
        width={parsedSize}
        height={parsedSize}
        alignItems='center'
        justifyContent='center'
      >
        <Icon
          name='home-filled'
          width={parsedSize / 2}
          height={parsedSize / 2}
        />
      </GuildDefaultFrame>
    )
  }

  return (
    <GuildPrimaryPhotoFrame
      {...props}
      alt={`Primary Photo for ${data.name}`}
      ref={ref}
      size={size}
      src={`${IMAGEKIT_URL}/tr:w-${parsedSize},h-${parsedSize},dpr-3/${
        data.primaryPhoto.rowId
      }.${data.primaryPhoto.contentType.toLowerCase()}`}
      width={parsedSize}
      height={parsedSize}
    />
  )
})
