import React, { forwardRef } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentDismiss = forwardRef(
  (
    {
      fillColor = '#212121',
      color = fillColor,
      size = 24,
      ...props
    }: { fillColor?: string; color?: string; size?: number } & SvgProps,
    ref
  ) => (
    <Svg
      ref={ref}
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M4.397 4.554l.073-.084a.75.75 0 01.976-.073l.084.073L12 10.939l6.47-6.47a.75.75 0 111.06 1.061L13.061 12l6.47 6.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073L12 13.061l-6.47 6.47a.75.75 0 01-1.06-1.061L10.939 12l-6.47-6.47a.75.75 0 01-.072-.976l.073-.084-.073.084z'
        fill={color}
      />
    </Svg>
  )
)

export default FluentDismiss
