import React, { useState, useMemo, useCallback } from 'react'
import { LayoutRectangle } from 'react-native'
import {
  styled,
  withStaticProperties,
  ThemeableStack,
  Token,
  createStyledContext,
  ThemeableStackProps,
} from 'tamagui'

import { Icon } from '../Icon'
import { Text } from '../Text'
import { Spinner } from '../Spinner'

import './Button.css'

export const ButtonContext = createStyledContext({
  secondary: false as boolean,
})

export type ButtonProps = ThemeableStackProps & {
  successFlash?: boolean
  errorFlash?: boolean
  forceState?: 'notPressed' | 'pressed' | 'success' | 'error'
  iconSize?: Token
}

const ButtonFrame = styled(ThemeableStack, {
  name: 'Button',
  context: ButtonContext,
  tag: 'button',
  role: 'button',

  pressStyle: {
    opacity: 0.4,
  },

  opacity: 1,

  paddingVertical: '$2',
  paddingHorizontal: '$3',

  borderRadius: '$6',
  outlineWidth: 0,

  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  cursor: 'pointer',

  borderWidth: '1px',

  minHeight: 34,

  className: 'new_Button newButton-notPressed',

  variants: {
    pressState: {
      notPressed: {
        className: 'new_Button new_Button-notPressed',
      },
      pressed: {
        className: 'new_Button new_Button-pressed',
        paddingVertical: 0,
      },
      success: {
        className: 'new_Button new_Button-success',
      },
      error: {
        className: 'new_Button new_Button-error',
      },
    },
    red: {
      true: {
        className: 'new_Button new_Button-error',
      },
      false: {},
    },
    secondary: {
      true: {
        className: 'new_Button new_Button-secondary',
      },
      false: {},
    },
    small: {
      true: {
        minHeight: 30,
        height: 30,
        paddingVertical: '$1',
        paddingHorizontal: '$2',

        borderRadius: '$3',
      },
      false: { minHeight: '$9' },
    },
  } as const,

  defaultVariants: {
    pressState: 'notPressed',
    secondary: false,
  } as const,
} as const)

const ButtonText = styled(Text, {
  name: 'ButtonText',
  context: ButtonContext,
  color: '$grey0',
  fontFamily: '$body',
  fontSize: '$true',
  fontWeight: '$6',
  lineHeight: '$true',
  userSelect: 'none',
  textShadowColor: 'rgba(0,0,0,0.3)',
  textShadowOffset: { width: 0, height: 1 },
  textShadowRadius: 4,

  // paddingVertical: '$2',

  variants: {
    secondary: {
      true: { color: '$grey8', textShadowColor: 'unset' },
      false: {},
    },
  } as const,

  // defaultVariants: {
  //   secondary: false,
  // } as const,
} as const)

export const Button = withStaticProperties(
  ButtonFrame.styleable<ButtonProps>(
    (
      {
        successFlash = true,
        errorFlash = true,
        forceState,
        iconSize = '$5',
        ...props
      },
      ref
    ) => {
      const [pressState, setPressState] = useState<
        'notPressed' | 'pressed' | 'success' | 'error'
      >('notPressed')

      const pressStateOrForcedState = forceState || pressState

      const [buttonDimensions, setButtonDimensions] =
        useState<LayoutRectangle>()

      const onPress = useCallback(
        async (event) => {
          if (!props.onPress) {
            return
          }

          // Don't double press
          if (pressStateOrForcedState !== 'notPressed') {
            if (typeof event.preventDefault === 'function') {
              event.preventDefault()
            }
            if (typeof event.stopPropagation === 'function') {
              event.stopPropagation()
            }

            return
          }

          setPressState('pressed')

          try {
            await props.onPress(event)

            if (successFlash) {
              setPressState('success')

              await new Promise<void>((resolve) =>
                setTimeout(() => resolve(), 2000)
              )
            }

            setPressState('notPressed')
          } catch (err) {
            if (errorFlash) {
              setPressState('error')

              await new Promise<void>((resolve) =>
                setTimeout(() => resolve(), 2000)
              )
            }

            setPressState('notPressed')
          }
        },
        [props.onPress, successFlash, errorFlash, pressStateOrForcedState]
      )

      const children = useMemo(() => {
        switch (pressStateOrForcedState) {
          case 'pressed':
            return (
              <Spinner
                size={iconSize}
                color={props.secondary ? '$grey8' : '$grey0'}
              />
            )

          case 'success':
            return (
              <Icon name='checkmark-filled' color='$grey0' size={iconSize} />
            )
          case 'error':
            return <Icon name='dismiss-filled' color='$grey0' size={iconSize} />
          case 'notPressed':
          default:
            return props.children
        }
      }, [props.children, pressStateOrForcedState, iconSize])

      const className = useMemo(() => {
        const classNames = ['new_Button']

        if (props.red) {
          classNames.push(`new_Button-${pressStateOrForcedState}-red`)
        } else if (props.secondary) {
          classNames.push(`new_Button-${pressStateOrForcedState}-secondary`)
        } else {
          classNames.push(`new_Button-${pressStateOrForcedState}`)
        }

        return classNames.join(' ')
      }, [pressStateOrForcedState, props.secondary, props.red])

      return (
        <ButtonFrame
          ref={ref}
          {...props}
          className={className}
          children={children}
          pressState={pressStateOrForcedState}
          onPress={onPress}
          onLayout={({ nativeEvent }) => {
            if (pressStateOrForcedState === 'notPressed') {
              setButtonDimensions(nativeEvent.layout)
            }
          }}
          // maxHeight={
          //   pressState !== 'notPressed' ? buttonDimensions?.height : undefined
          // }
          minWidth={
            pressStateOrForcedState !== 'notPressed'
              ? buttonDimensions?.width
              : undefined
          }
          // minHeight={
          //   pressStateOrForcedState !== 'notPressed'
          //     ? buttonDimensions?.height
          //     : undefined
          // }
        />
      )
    }
  ),
  { Text: ButtonText }
)
